import React, { Component } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Form, Icon, Upload, Switch } from "antd";
import Select, { SelectOption } from "../../components/uielements/select";
import Input, { Textarea } from "../../components/uielements/input";
import Spin from "../../components/Spin/spin.style";
import Tooltip from "../../components/uielements/tooltip";
import Modal from "../../components/feedback/modal";
import appActions from "../../redux/appVersions/actions";
import ModalWrapper from "./modal.style";
import { filterOption, validatPhone } from "../../helpers/utility";
import { getAppUsers } from "../../redux/appVersions/selecters";
import createNotification from "../../components/notification";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import API from "../../helpers/API";
import * as Yup from 'yup';
import {
  getOrganizationsAuth,
  getQualityPermission,
  getRole,
  getUserSchool
} from "../../redux/auth/selectors";
import {
  acceptImageTypes,
} from "../../helpers/utility";
import CropToolModal from "../../components/CropTool/CropToolModal";
import { withRouter } from 'react-router-dom';
import odinLogo from '../../image/customer.svg';
const { fetchUsers } = appActions;

const FormItem = Form.Item;
const Option = SelectOption;

const sortArray = (array, string) => {
  array.sort((a, b) => {
    let fa = a[string].toLowerCase(),
      fb = b[string].toLowerCase();

    if (fa < fb) {
      return -1;
    }
    if (fa > fb) {
      return 1;
    }
    return 0;
  });
  return array;
};

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

class AddTicket extends Component {
  state = {
    modalActive: false,
    user: undefined,
    school: undefined,
    email: undefined,
    phone: undefined,
    comment: undefined,
    ticketId: undefined,
    loading: false,
    schoolList: [],
    forMe: false,
    hower: false,
  };

  // componentDidMount() {
  //   // const { from } = this.props.location.state || {};
  //   // from === "home" && this.initAddSchool();
  //   setTimeout(() => {
  //     if (this.state.forMe) {
  //       console.log("keri")
  //       const user = JSON.parse(localStorage.getItem('userData'))
  //       console.log(user)
  //       this.props.form.setFieldsValue({
  //         email: user?.email ? user.email : "",
  //         // phone: selUser.phone ? selUser.phone : "",
  //       });
  //       this.setState({
  //         email: user?.email ? user.email : "",
  //         // phone: selUser?.phone ? selUser.phone : "",
  //       });
  //     }
  //   }, 200);
    
  // };

  // componentDidUpdate(nextProps) {
  //   (nextProps?.home && nextProps?.modalActive) && this.setState({ modalActive: nextProps?.modalActive});
  // }

  componentWillReceiveProps(nextProps) {
    if (nextProps.ticket && nextProps.ticket != this.props.ticket) {
      this.props.form.setFieldsValue({
        user: nextProps.ticket.user,
        school: nextProps.ticket.school,
        email: nextProps.ticket.email,
        phone: nextProps.ticket.phone,
        comment: nextProps.ticket.comment,
      });
      this.setState({
        modalActive: nextProps.modalActive,
        user: nextProps.ticket.user,
        school: nextProps.ticket.school,
        comment: nextProps.ticket.comment,
        email: nextProps.ticket.email,
        phone: nextProps.ticket?.phone,
        ticketId: nextProps.ticket?.id,
      });
    }
  }

  /**
   * Fetch school list
   */
  fetchSchool = async () => {
    try {
      const result = await API.schools.listAll();
      if (Array.isArray(result?.data?.schools)) {
        this.setState({ schoolList: result.data.schools });
      } else {
        this.setState({ schoolList: [] });
      }
    } catch (error) {
      this.setState({ schoolList: [] });
    }
  };

  handleCancel = () => {
    this.closeModal();
  };

  handleOnChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSchoolChange = (val) => {
    this.setState({ school: val === -1 ? undefined : val, userLoader: true, keySchool: null, user: undefined });
    this.props.form.setFieldsValue({
      user: undefined,
    });
    const payload = {
      school: val === -1 ? undefined : val,
    };
    this.props.fetchUsers(payload, () => {
      this.setState({ userLoader: false });
    });
  };

  searchSchool = (val) => {
    this.setState({ keySchool: val });
  };

  handleUserChange = (val) => {
    this.setState({ keyUser: "" });
    this.setState({ user: val });
    const selUser = (Array.isArray(this.props?.users) ? this.props.users : []).find((user) => user?.id === val);
    if (selUser) {
      this.props.form.setFieldsValue({
        email: selUser?.email ? selUser.email : "",
        phone: selUser.phone ? selUser.phone : "",
      });
      this.setState({
        email: selUser?.email ? selUser.email : "",
        phone: selUser?.phone ? selUser.phone : "",
      });
    }
  };

  searchUser = (val) => {
    this.setState({ keyUser: val });
  };

  initAddSchool = () => {
    if (this.props?.role === 'SUPER_ADMIN' || this.props.role === "ODIN_ADMIN") {
      this.fetchSchool();
    } else if (Array.isArray(this.props?.userSchhol)) {
      this.setState({ schoolList: this.props?.userSchhol });
    }

    this.setState({
      modalActive: true,
      user: undefined,
      school: undefined,
      comment: undefined,
      email: undefined,
      phone: undefined,
      ticketId: undefined,
      loading: false,
      schools: []
    });
    this.props.form.setFieldsValue({
      user: undefined,
      school: undefined,
      comment: undefined,
      email: undefined,
      phone: undefined,
      ticketId: undefined,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        this.setState({ loading: true });

        try {
          const { user, school, email, phone, comment } = this.state;
          const payload = {
            student: user,
            school,
            email: (typeof email === 'string' && email.trim() !== '') ? email : undefined,
            phone: (typeof phone === 'string' && phone.trim() !== '') ? phone : undefined,
            issue: comment,
            resources: this.state.myLibraryIcon ? [this.state.myLibraryIcon] : [],
            forAdmin: this.state?.forMe
          };
          const healthData = await API.health.create(payload);
          // const socketData = await API.health.createSocket({
          //   roomType: "techSupport",
          //   id: healthData?.data?.techSupport?.id,
          //   participants: [
          //     {
          //       "isStudent": false,
          //       "userId": JSON.parse(localStorage.getItem('userData')).id
          //     },{
          //       "isStudent": true,
          //       "userId": user
          //     }
          //     // ,{
          //     //   "isStudent": false,
          //     //   "userId": 46
          //     // }
          //   ]
          // });
          // console.log(healthData);
          // console.log(socketData)
          createNotification("success", "New support ticket created");
          this.setState({ loading: false });
          this.closeModal();
        } catch (err) {
          let errMsg = "Session timed out. Please login again";
          if (err?.errors?.length && typeof err?.errors[0]?.message === "string") {
            errMsg = err?.errors[0]?.message;
          } else if (typeof err?.error === "string") {
            errMsg = err.error;
          } else if (typeof err?.error?.message === "string") {
            errMsg = err.error.message;
          } else if (typeof err?.message === "string") {
            errMsg = err.message;
          }
          createNotification("error", errMsg);
          this.setState({ loading: false });
          this.closeModal();
        }

      }
    });
  };

  descValidator = (rule, value, callback) => {
    const { form } = this.props;
    if (form.getFieldValue('comment')?.length > 250) {
      callback('description should shorter than 250 characters');
    } else {
      callback();
    }
  }

  uploadImage = async ({ data, file, onSuccess }) => {
    getBase64(file, async (fileUrl) => {
      onSuccess("done", fileUrl);
      this.setState({ openCropTool: true, imgSrc: fileUrl });
    });
  };

  setOpenCropModal = () => {
    this.setState({ openCropTool: false });
  };

  uploadAvatar = async (file) => {
    // this.loaderHandle(true);
    const _this = this;
    getBase64(file, async (fileUrl) => {
      const fileData = new FormData();
      fileData.append("file", file?.originFileObj ?? file);
      try {
        let coverList = Object.assign({}, this.state.coverList);
        coverList = {
          uid: "-1",
          // name: file.name,
          status: "done",
          originFileObj: file,
          url: fileUrl,
          thumbUrl: fileUrl,
        };
        const fileUpload = await API.upload.add(fileData);

        this.setState({
          myLibraryIcon: fileUpload?.data?.id,
          openCropTool: false,
          imgSrc: fileUrl,
          coverList: [coverList],
        });
        // this.loaderHandle(false);
      } catch (err) {
        // this.loaderHandle(false);
        createNotification(
          "error",
          err && err.error
            ? err.error?.message || err.message
            : "Session timed out. Please login again"
        );
      }
    });
  };

  closeModal = (val) => {
    if (!val) {
      this.setState({
        modalActive: false,
        user: undefined,
        school: undefined,
        email: undefined,
        phone: undefined,
        comment: undefined,
        ticketId: undefined,
        loading: false,
        myLibraryIcon: undefined,
        imgSrc: undefined,
        coverList: undefined,
      });
      this.props.form.setFieldsValue({
        user: undefined,
        school: undefined,
        email: undefined,
        phone: undefined,
        comment: undefined,
        ticketId: undefined,
        icon: undefined,
      });
      this.props.addLoading();
      this.props.modalHandle(false);
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  onChangePrivate = (val) => {
    const user = JSON.parse(localStorage.getItem('userData'))
        this.props.form.setFieldsValue({
          email: val ? user.email : "",
          phone: val ? user.phone : "",
        });
        this.setState({
          email: val ? user.email : "",
          forMe: val,
          phone: val ? user.phone : "",
        });
  };

  render() {
    const {
      modalActive,
      ticketId,
      keySchool,
      keyUser,
      schoolList,
    } = this.state;
    const { getFieldDecorator } = this.props.form;
    let { users } = this.props;
    const schools = schoolList ? sortArray(schoolList, "name") : schoolList;
    users = users ? sortArray(users, "firstName") : users;

    const filteredSchools =
      schools && keySchool
        ? schools.filter((school) =>
          (typeof school?.name === 'string' ? school.name : '').toLowerCase().includes(keySchool.toLowerCase())
        )
        : schools;

    const filteredUsers =
      users && keyUser
        ? users.filter(
          (user) =>
            user.firstName.toLowerCase().includes(keyUser.toLowerCase()) ||
            (user.lastName &&
              user.lastName.toLowerCase().includes(keyUser.toLowerCase()))
        )
        : users;
    const uploadButton = (
      <div>
        <Icon type={false ? "loading" : "plus"} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );

    return (
      <div>
        {!this.props?.home ? <div className="export-row">
          <Tooltip placement="topRight" title="New Ticket">
            <Icon
              type="plus-circle"
              theme="outlined"
              onClick={this.initAddSchool}
              className="add-icon-style"
            />
          </Tooltip>
        </div>:
        <div onClick={this.initAddSchool}>
        <div 
        onMouseEnter={() => this.setState({hower: true})}
          onMouseLeave={() => this.setState({hower: false})}
          className={this.state.hower ? "floating-beta hoveredImage" : "floating-beta"}>
          <img className="zoom" src={odinLogo} width={130} height={130} />
        </div>
      </div>
        }
        <Modal
          visible={modalActive}
          onClose={this.handleCancel}
          title={`${ticketId ? `Edit Ticket` : `New Ticket`}`}
          okText={`${ticketId ? "Update" : "Add"}`}
          onOk={this.handleSubmit}
          onCancel={this.handleCancel}
          okButtonProps={{ loading: this.state.loading }}
          cancelButtonProps={{ disabled: this.state.loading }}
          maskClosable={false}
        >
          <Spin spinning={this.state.loading}>
            <ModalWrapper>
              <Form layout="inline">
                <FormItem className="ant-row" style={{ display: "flex", justifyContent: "center" }}>
                  {getFieldDecorator("icon", {
                    rules: [
                      // {
                      //   required: true,
                      //   message: "Please add a comment",
                      // },
                    ],
                  })(
                    <Upload
                      name="monday"
                      accept={acceptImageTypes}
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      customRequest={this.uploadImage}
                    >
                      {this.state.coverList?.length >= 1 ? (
                        <img
                          src={this.state.coverList[0]?.url}
                          alt="avatar"
                          style={{ width: "100%" }}
                        />
                      ) : (
                        uploadButton
                      )}
                    </Upload>
                  )}
                </FormItem>

                <FormItem label="For me/student" className="tech-form">
                  {getFieldDecorator("public", {})(
                    <Switch
                      // disabled={websiteId}
                      // style={{ minWidth: "100px" }}
                      style={{
                        minWidth: "100px",
                        backgroundColor: "#1d73b3",
                      }}
                      onChange={(val, e) => {
                        // onChange(data, val);
                        this.onChangePrivate(val);
                      }}
                      checked={this.state.forMe}
                      checkedChildren="Me"
                      unCheckedChildren="Student"
                    />
                  )}
                </FormItem>
                {!this.state.forMe && 
                <>
                <FormItem label="School" className="tech-form">
                  {getFieldDecorator("school", {
                    rules: [
                      {
                        required: true,
                        message: "Please select school",
                      },
                    ],
                  })(
                    <Select
                      showSearch
                      filterOption={filterOption}
                      showArrow={false}
                      onChange={this.handleSchoolChange}
                      style={{ width: "220px" }}
                      id="school"
                      placeholder={"Select School"}
                      onSearch={this.searchSchool}
                    >
                      {filteredSchools &&
                        filteredSchools.map((school, key) => (
                          <Option key={key} value={school?.id}>
                            {school.name}
                          </Option>
                        ))}
                    </Select>
                  )}
                </FormItem>
                <FormItem label="User" className="tech-form">
                  {getFieldDecorator("user", {
                    rules: [
                      {
                        required: true,
                        message: "Please select user",
                      },
                    ],
                  })(
                    <Select
                      showSearch
                      filterOption={filterOption}
                      showArrow={true}
                      onChange={this.handleUserChange}
                      maxTagCount={1}
                      onSearch={this.searchUser}
                      style={{ width: "220px" }}
                      id="user"
                      placeholder={"Select User"}
                    >
                      {(Array.isArray(filteredUsers) ? filteredUsers : []).map((user, key) => (
                        <Option key={key} value={user?.id}>{`${typeof user?.firstName === 'string' ? user.firstName : ''
                          } ${typeof user?.lastName === 'string' ? user.lastName : ''}`}</Option>
                      ))}
                    </Select>
                  )}
                </FormItem>
                </>}  
                <FormItem label="Email" className="tech-form">
                  {getFieldDecorator("email", {
                    rules: [
                      {
                        validator: async (_, value) => {
                          try {
                            const schema = Yup.string().optional().email('Invalid email')
                            await schema.validateSync(value);

                            return Promise.resolve();
                          } catch (error) {
                            return Promise.reject(new Error('Invalid email'));
                          }
                        },
                      },
                    ],
                  })(
                    <Input
                      size="default"
                      id="email"
                      style={{ width: "220px" }}
                      placeholder="Enter User Email"
                      name="email"
                      onChange={this.handleOnChange}
                    />
                  )}
                </FormItem>
                <FormItem label="Phone" className="tech-form">
                  {getFieldDecorator("phone", {
                    rules: [
                      {
                        validator: validatPhone,
                      },
                    ],
                  })(
                    <PhoneInput
                      inputProps={{
                        style: { width: "220px" },
                      }}
                      country={"za"}
                      onlyCountries={['za', 'in']}
                      inputClass="ant-input"
                      onKeyDown={(value) => {
                        if (value?.key === "Backspace" && value.target.value?.length === 3) {
                          value.preventDefault();
                        }
                      }}
                      onChange={(value, data) => {
                        const minimum =
                          data.format.match(/\./g).length -
                          data.dialCode.length;
                        this.setState({
                          min: minimum,
                          phone: `+${value}`,
                        });
                      }}
                      placeholder="Enter phone number"
                    />
                  )}
                </FormItem>
                <FormItem label="Issue Description" className="issue-class tech-form">
                  {getFieldDecorator("comment", {
                    rules: [
                      {
                        required: true,
                        message: "Please fill in the field",
                      },
                      {
                        validator: this.descValidator,
                      }
                    ],
                  })(
                    <Textarea
                      name="comment"
                      style={{ width: "220px" }}
                      placeholder="Description"
                      onChange={this.handleOnChange}
                      rows={2}
                      cols={23}
                    />
                  )}
                </FormItem>
              </Form>
            </ModalWrapper>
          </Spin>
          <CropToolModal
            openCropModal={this.state?.openCropTool}
            setOpenCropModal={this.setOpenCropModal}
            imgSrc={this.state.imgSrc}
            imageName={this.state?.avatarList?.originFileObj?.name}
            submitImage={this.uploadAvatar}
            imgToCrop={this.state.avatarList}
            fileError={""}
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  users: getAppUsers(),
  role: getRole(),
  userOrg: getOrganizationsAuth(),
  userSchhol: getUserSchool(),
  permission: getQualityPermission(),
});

function mapDispatchToProps(dispatch) {
  return {
    fetchUsers: (payload, spinHandler) =>
      dispatch(fetchUsers(payload, spinHandler)),
  };
}
export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)((AddTicket = Form.create()(AddTicket))));
